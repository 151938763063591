import React from 'react'
import Layout from '../components/App/Layout';
import {Link} from 'gatsby'
import SEO from '../components/App/SEO'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { getDownloadUrlByOsVersion } from '../utils/redirect'
import { osName } from 'react-device-detect';
import { graphql } from 'gatsby'

const img2 = '/img/desktop/BlueMail-Windows-Application.png'
const img3 = '/img/desktop/Bluemail-Linux-Application.png'
const img4 = '/img/desktop/BlueMail-Mac-Application.png'

const desktopPage = ({data}) => (
      <Layout env={data.site.siteMetadata.env}>
          <Navbar />
      <div className="desktop-area container pt-120 pb-120">
        <SEO
          postTitle="Desktop | BlueMail App"
          postDescription="BlueMail is now bringing the same powerful features to the desktop, for  Windows, Mac, and Linux."
          postImage="/img/desktop/BlueMail-Windows-Application.png"
          postURL="desktop"
          postSEO
        />
        <div className="row">
          <div className="col-12 center-text" >
            <h1>
              BlueMail is already here for{' '}
              <Link className="hovered-link" to="/desktop/windows/">
                Windows
              </Link>
              ,{' '}
              <Link className="hovered-link" to="/desktop/mac/">
                Mac
              </Link>
              , &{' '}
              <Link className="hovered-link" to="/desktop/linux/">
                Linux
              </Link>
            </h1>
            <p>
              BlueMail – the favorite email client for millions of Android and iOS
              users, can now be enjoyed on your desktop as well.
            </p>
            <hr />
          </div>
        </div>
        <div className="row center-text mt-50">
          <div className="col-lg-4 col-md-12 col-sm-12" style={{ marginTop: '24px', padding: '10px', maxWidth: '100%' }}>
            <a href="https://apps.apple.com/us/app/bluemail/id1458754578?mt=12" target="_blank" rel="noreferrer">
              <img src={img4} style={{ marginTop: '19px', maxWidth: '100%', minHeight: '241px' }} alt="BlueMail Mac OS"/>
            </a>
            <div style={{ margin: '4px auto 0px' }}>
              <a href="https://apps.apple.com/us/app/bluemail/id1458754578?mt=12" target="_blank" rel="noreferrer">
                <button className="default-btn" style={{ marginTop: '10px' }}>
                  Download for Mac</button>
              </a>
            </div>
          </div>
    
          <div className="col-lg-4 col-md-12 col-sm-12" style={{ margin: '24px auto 0 auto', padding: '10px', maxWidth: '100%' }}>
            <a href={osName === 'Windows' ? getDownloadUrlByOsVersion() : getDownloadUrlByOsVersion()} target="_blank" rel="noreferrer">
              <img src={img2} style={{ maxWidth: '100%' }} alt="BlueMail Windows 7, Windows 8, and Windows 10"/>
            </a>
            <div style={{ margin: '20px auto 0 auto' }}>
              <a href={osName === 'Windows' ? getDownloadUrlByOsVersion() : getDownloadUrlByOsVersion()} target="_blank" rel="noreferrer">
                <button className="default-btn">Download for Windows</button>
              </a>
            </div>
          </div>
    
          <div className="col-lg-4 col-md-12 col-sm-12" style={{ marginTop: '24px', padding: '10px', maxWidth: '100%' }}>
            <Link to="/desktop/linux/">
              <img src={img3} style={{ marginTop: '32px', maxWidth: '100%' }} alt="BlueMail for Linux"/>
            </Link>
            <div className="mt-20">
              <Link to="/desktop/linux/">
                <button className="default-btn" style={{ marginTop: '10px' }}>
                  Download for Linux
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="center-text mt-50">
              BlueMail for Android and iOS changed how people manage their email on
              their mobile devices and is now bringing the same robust features,
              immense customization possibilities, and user-friendly design to the
              desktop. BlueMail for desktop provides a more powerful fully featured
              experience than the traditional webmail client, and you’ll never look
              at your inbox the same way again.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
)

export default desktopPage

export const query = graphql`
query DesktopPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
